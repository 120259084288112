import { client } from "../axios/axios";
import { uploadFileToDrive } from "../functions/navFunctions";

export const passportPatch = (
  formStatePassport,
  id,
  setIsSubmitted,
  setRedirect
) => {
  const passportObject = {
    applicationType: "Passporting",
    typeOfApplication: {
      benefitReceiving: formStatePassport.benefit,
      PleaseConfirmThatYouHaveWatchedThisVideoAndYouHaveTheRequiredEvidence:
        "Yes",
    },
    evidence: [],
    personalDetails: {
      firstName: formStatePassport.firstName,
      surname: formStatePassport.familyName,
      isSurnameAtBirthDifferent: formStatePassport.differentFamilyName,
      dateOfBirth: formStatePassport.dob,
      address: {
        street: formStatePassport.street,
        city: formStatePassport.city,
        postcode: formStatePassport.postCode,
        country: formStatePassport.country,
      },
      nationalInsuranceNumber: formStatePassport.insuranceNumber,
      telephoneNumber: formStatePassport?.phone,
      emailAddress: formStatePassport.email,
      findDMS: formStatePassport.find,
      maritalStatus: formStatePassport.maritalStatus,
    },
    properties: [],
    assets: {},
    case: {
      caseAbout: formStatePassport.caseAbout,
      discussFinancialIssues: formStatePassport.financialIssues,
      "discussOtherPArtyPROPERTY&FINANCES":
        formStatePassport.otherPartyProperty,
    },
    accommodation: {
      ownProperty: formStatePassport.property,
      accommodationLiveInType: formStatePassport.accommodationTypeLive,
    },
    notIncludedInFinancialAssets: {
      savingsAmount: formStatePassport.savingMoneyAmount,
      investmentsAmount: formStatePassport.investmentMoneyAmount,
      valuableItemsWorth: formStatePassport.valuableItemsWorth,
      otherCapitalValue: formStatePassport.capitalValue,
    },
    previousRelationshipDetails: {
      otherPersonSurname: formStatePassport.otherPersonSurname,
      isMarriedTo: formStatePassport.marriedCheck,
      beenInMediationBefore: formStatePassport.mediationBefore,
    },
    clientDeclaration: {
      IUnderstandThatTheLegalAidAgencyMayCheckWhetherTheInformationAndDocumentationThatIProvideIsGenuineAndCorrect:
        "Yes",
      IUnderstandThatTheInformationObtainedWillBeUsedToDecideMyApplicationForLegalAidAndForRelatedPurposes:
        "Yes",
      IUnderstandThatProvidingInformationOrDocumentationThatIsNotGenuineAndOrCorrectWillResultInMyApplicationOrSubsequentApplicationsBeingRefusedAndMayLeadToMyProsecutionForACriminalOffence:
        "Yes",
      signature: formStatePassport?.url?.split(",")[1],
    },
  };
  if (passportObject.personalDetails.isSurnameAtBirthDifferent === "Yes") {
    passportObject.personalDetails.differentSurnameAtBirth =
      formStatePassport.birthFamilyName;
  }
  if (
    passportObject.personalDetails.maritalStatus ===
      "Cohabiting with new partner" ||
    passportObject.personalDetails.maritalStatus === "Married to a new partner"
  ) {
    passportObject.personalDetails.IsYourCurrentPartnerListedOnTheSameBenefitsClaim =
      formStatePassport.currentPartner;
  }
  if (
    passportObject.personalDetails
      .IsYourCurrentPartnerListedOnTheSameBenefitsClaim === "Yes"
  ) {
    passportObject.partnerFinancialAssets = {
      savingsAmount: formStatePassport.savingMoneyAmountOtherParty,
      investmentsAmount: formStatePassport.investmentMoneyAmountOtherParty,
      valuableItemsWorth: formStatePassport.valuableItemsWorthOtherParty,
      otherCapitalValue: formStatePassport.capitalValueOtherParty,
    };
  }
  if (passportObject.case.caseAbout !== "Child Arrangements") {
    passportObject.assets.backAccountSavingDiscussion =
      formStatePassport.relatedDiscussions;
    if (passportObject.assets.backAccountSavingDiscussion === "Yes") {
      passportObject.assets.backAccountSavingAmount =
        formStatePassport.savingAmount;
    }
  }
  if (
    passportObject.case.discussFinancialIssues === "Yes" &&
    passportObject.case["discussOtherPArtyPROPERTY&FINANCES"] === "Yes"
  ) {
    passportObject.assets = {
      pensionsDiscussions: formStatePassport.pensionsDiscussions,
    };
    if (passportObject.assets.pensionsDiscussions === "Yes") {
      passportObject.assets.pensionsValue = formStatePassport.pensionsValue;
      passportObject.assets.otherPartyPensionValue =
        formStatePassport.otherPartyPensionsValue;
    }
  }
  if (
    passportObject.case.discussFinancialIssues === "Yes" ||
    passportObject.case["discussOtherPArtyPROPERTY&FINANCES"] === "Yes" ||
    passportObject.accommodation.ownProperty === "Yes"
  ) {
    passportObject?.properties?.push({
      "Property One": {
        propertyWorth: formStatePassport.propertyWorth,
        outstandingMortgageProperty: formStatePassport.mortgageProperty,
        "isPropertyInJointNamesWithYourEx-partner":
          formStatePassport.outstandingMortgage,
        propertyShare: formStatePassport.propertyShare,
        isPropertyDiscussion: formStatePassport.discussionsProperty,
        isSecondProperty: formStatePassport.secondPropertyCheck,
      },
    });
  }
  if (
    (passportObject.case.discussFinancialIssues === "Yes" ||
      passportObject.case["discussOtherPArtyPROPERTY&FINANCES"] === "Yes" ||
      passportObject.accommodation.ownProperty === "Yes") &&
    passportObject?.properties[0]["Property One"]?.isPropertyDiscussion ===
      "Yes"
  ) {
    passportObject.assets.investmentsInvolvingDiscussions =
      formStatePassport.investmentDiscussions;
    passportObject.assets.valuableItemsDiscussions =
      formStatePassport.valuableItems;
    if (passportObject.assets.investmentsInvolvingDiscussions === "Yes") {
      passportObject.assets.investmentsAmount =
        formStatePassport.investmentsAmount;
    }
    if (passportObject.assets.valuableItemsDiscussions === "Yes") {
      passportObject.assets.valuableItemsAmount =
        formStatePassport.valuableItemsAmount;
    }
  }
  if (
    (passportObject.case.discussFinancialIssues === "Yes" ||
      passportObject.case["discussOtherPArtyPROPERTY&FINANCES"] === "Yes" ||
      passportObject.accommodation.ownProperty === "Yes") &&
    passportObject?.properties[0]["Property One"]?.isSecondProperty === "Yes"
  ) {
    passportObject?.properties?.push({
      "Property Two": {
        propertyWorth: formStatePassport.secondHouse,
        outstandingMortgageProperty: formStatePassport.mortgageOutstanding,
        "isPropertyInJointNamesWithYourEx-partner":
          formStatePassport.jointNames,
        propertyShare: formStatePassport.secondHousePropertyShare,
        isThirdProperty: formStatePassport.thirdPropertyCheck,
      },
    });
  }
  if (passportObject?.properties[1]) {
    if (
      passportObject?.properties[1]["Property Two"]?.isThirdProperty === "Yes"
    ) {
      passportObject?.properties?.push({
        "Property Three": {
          propertyWorth: formStatePassport.thirdHouse,
          outstandingMortgageProperty:
            formStatePassport.mortgageOutstandingThirdHouse,
          "isPropertyInJointNamesWithYourEx-partner":
            formStatePassport.jointNamesThirdHouse,
          propertyShare: formStatePassport.thirdHousePropertyShare,
        },
      });
    }
  }
  if (passportObject.accommodation.ownProperty === "Yes") {
    passportObject.accommodation.lastMonthMortgagePay =
      formStatePassport.mortgagePay;
  }
  if (passportObject.accommodation.accommodationLiveInType === "Rent") {
    passportObject.accommodation.accommodationRentType =
      formStatePassport.accommodationTypeRent;
    passportObject.accommodation.lastMonthRentPay = formStatePassport.rentPay;
  }
  if (
    passportObject.previousRelationshipDetails.beenInMediationBefore === "Yes"
  ) {
    passportObject.previousRelationshipDetails.mediationType =
      formStatePassport.mediationTypes;
  }

  client
    .patch(`/legalAidClients/submit/${id}`, passportObject, {
      headers: { "Content-Type": "application/json" },
    })
    .then(() => {
      uploadFileToDrive(id, formStatePassport, setRedirect);
      setIsSubmitted(true);
    })
    .catch((err) => console.log({ err: err.message }));
};
