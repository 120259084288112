import React, { useState } from "react";
import YesNoInput from "../FormComponents/Components/YesNoInput";
import useScrollTo from "../hooks/useScrollTo";
import TextInput from "../FormComponents/Components/TextInput";

const Assets = ({ state, dispatch }) => {
  const [relatedDiscussions, setRelatedDiscussions] = useState(
    state.relatedDiscussions
  );
  const [savingAmount, setSavingAmount] = useState(state.savingAmount || "");
  const [investmentDiscussions, setInvestmentDiscussions] = useState(
    state.investmentDiscussions
  );
  const [investmentsAmount, setInvestmentsAmount] = useState(
    state.investmentsAmount || ""
  );
  const [valuableItems, setValuableItems] = useState(state.valuableItems);
  const [valuableItemsAmount, setValuableItemsAmount] = useState(
    state.valuableItemsAmount || ""
  );
  const [pensionsDiscussions, setPensionsDiscussions] = useState(
    state.pensionsDiscussions
  );
  const [pensionsValue, setPensionsValue] = useState(state.pensionsValue || "");
  const [otherPartyPensionsValue, setOtherPartyPensionsValue] = useState(
    state.otherPartyPensionsValue || ""
  );

  useScrollTo(0, 0, "smooth");

  const onRelatedDiscussionsChange = ({ target }) => {
    setRelatedDiscussions(target.value);
    dispatch({ type: "RELATED_DISCUSS", payload: target.value });
  };
  const onSavingAmountChange = ({ target }) => {
    setSavingAmount(target.value);
    dispatch({ type: "Saving_Amount", payload: target.value });
  };
  const onInvestmentDiscussionsChange = ({ target }) => {
    setInvestmentDiscussions(target.value);
    dispatch({ type: "Investment_Discussions", payload: target.value });
  };
  const onInvestmentsAmountChange = ({ target }) => {
    setInvestmentsAmount(target.value);
    dispatch({ type: "Investments_Amount", payload: target.value });
  };
  const onValuableItemsChange = ({ target }) => {
    setValuableItems(target.value);
    dispatch({ type: "Valuable_Items", payload: target.value });
  };
  const onValuableItemsAmountChange = ({ target }) => {
    setValuableItemsAmount(target.value);
    dispatch({ type: "Valuable_Items_Amount", payload: target.value });
  };
  const onPensionsDiscussionsChange = ({ target }) => {
    setPensionsDiscussions(target.value);
    dispatch({ type: "Pensions_Discussions", payload: target.value });
  };
  const onPensionsValueChange = ({ target }) => {
    setPensionsValue(target.value);
    dispatch({ type: "Pensions_Value", payload: target.value });
  };
  const onOtherPartyPensionsValueChange = ({ target }) => {
    setOtherPartyPensionsValue(target.value);
    dispatch({ type: "OtherPartyPensions_Value", payload: target.value });
  };

  return (
    <>
      <h2>Assets to be included in the mediation discussions</h2>
      {state.caseAbout !== "Child Arrangements" && (
        <YesNoInput
          label="Are your discussions related to savings in a bank account?"
          checkedTerm={relatedDiscussions}
          onOptionChange={onRelatedDiscussionsChange}
          yesLabel="Yes"
          noLabel="No"
          noId="no-related-discussions-saving"
          yesId="yes-related-discussions-saving"
          name="related-discussions"
          required={true}
        />
      )}
      {state.caseAbout !== "Child Arrangements" &&
        relatedDiscussions === "Yes" && (
          <TextInput
            label="What is the amount of savings that you are willing to discuss?"
            guide="Please add the value in British pounds (£)"
            value={savingAmount}
            onChangeText={onSavingAmountChange}
            id="saving-amount"
            required={true}
          />
        )}
      {state.discussionsProperty === "Yes" && (
        <YesNoInput
          label="Are your discussions involving to investments?"
          checkedTerm={investmentDiscussions}
          onOptionChange={onInvestmentDiscussionsChange}
          yesLabel="Yes"
          noLabel="No"
          noId="no-invest-discussions"
          yesId="yes-invest-discussions"
          required={true}
          name="invest-discussions"
        />
      )}
      {state.discussionsProperty === "Yes" &&
        investmentDiscussions === "Yes" && (
          <TextInput
            label="What is the amount in investments?"
            guide="Please add the value in British pounds (£)"
            value={investmentsAmount}
            onChangeText={onInvestmentsAmountChange}
            id="saving-investments"
            required={true}
          />
        )}
      {state.discussionsProperty === "Yes" && (
        <YesNoInput
          label="Are your discussions about any valuable items (boat, caravan, jewellery, etc)?"
          checkedTerm={valuableItems}
          onOptionChange={onValuableItemsChange}
          required={true}
          noId="no-valuable-items"
          yesId="yes-valuable-items"
          noLabel="No"
          yesLabel="Yes"
          name="valuable-items"
        />
      )}
      {state.discussionsProperty === "Yes" && valuableItems === "Yes" && (
        <TextInput
          label="What is the amount in valuable items?"
          guide="Please add the value in British pounds (£)"
          value={valuableItemsAmount}
          onChangeText={onValuableItemsAmountChange}
          id="valuableItems"
          required={true}
        />
      )}
      <YesNoInput
        label="Are your discussions related to pensions?"
        checkedTerm={pensionsDiscussions}
        onOptionChange={onPensionsDiscussionsChange}
        required={true}
        noId="no-discussions"
        yesId="yes-discussions"
        noLabel="No"
        yesLabel="Yes"
        name="discussions"
      />
      {pensionsDiscussions === "Yes" && (
        <>
          <TextInput
            label="What is the value of your pension?"
            value={pensionsValue}
            onChangeText={onPensionsValueChange}
            id="pensionsValue"
            required={true}
          />
          <TextInput
            label="What is the value of the other participant`s pension?"
            value={otherPartyPensionsValue}
            onChangeText={onOtherPartyPensionsValueChange}
            id="otherParty-Pensions-Value"
            required={true}
          />
        </>
      )}
    </>
  );
};

export default Assets;
