import { useState } from "react";
import useScrollTo from "../hooks/useScrollTo";
import TextInput from "../FormComponents/Components/TextInput";
import YesNoInput from "../FormComponents/Components/YesNoInput";

const YourThirdHouse = ({ state, dispatch }) => {
  const [thirdHouse, setThirdHouse] = useState(state.thirdHouse || "");
  const [mortgageOutstandingThirdHouse, setMortgageOutstandingThirdHouse] =
    useState(state.mortgageOutstandingThirdHouse || "");
  const [jointNamesThirdHouse, setJointNamesThirdHouse] = useState(
    state.jointNamesThirdHouse
  );
  const [thirdHousePropertyShare, setThirdHousePropertyShare] = useState(
    state.thirdHousePropertyShare || ""
  );

  useScrollTo(0, 0, "smooth");

  const onThirdHouseProperty = ({ target }) => {
    setThirdHouse(target.value);
    dispatch({ type: "THIRD_HOUSE", payload: target.value });
  };
  const onMortgageOutstandingThirdHouseChange = ({ target }) => {
    setMortgageOutstandingThirdHouse(target.value);
    dispatch({
      type: "MORTGAGE_OUTSTANDING_THIRD_HOUSE",
      payload: target.value,
    });
  };
  const onJointNamesThirdHouseChange = ({ target }) => {
    setJointNamesThirdHouse(target.value);
    dispatch({ type: "JOINT_NAMES_THIRD_HOUSE", payload: target.value });
  };
  const onThirdHousePropertyShareChange = ({ target }) => {
    setThirdHousePropertyShare(target.value);
    dispatch({ type: "THIRD_HOUSE_PROPERTY_SHARE", payload: target.value });
  };

  return (
    <>
      <h2>Your Third House</h2>
      <TextInput
        label="What do you think your third house is worth according to the deeds?"
        id="third_house"
        value={thirdHouse}
        onChangeText={onThirdHouseProperty}
        required={true}
      />
      <TextInput
        label="What is your outstanding mortgage?"
        id="outstanding_mortgage_Third_house"
        value={mortgageOutstandingThirdHouse}
        onChangeText={onMortgageOutstandingThirdHouseChange}
        required={true}
      />
      <YesNoInput
        label="Is property in joint names with your ex-partner?"
        noLabel="No"
        yesLabel="Yes"
        noId="no-joint-third"
        yesId="yes-joint-third"
        required={true}
        checkedTerm={jointNamesThirdHouse}
        onOptionChange={onJointNamesThirdHouseChange}
      />
      <TextInput
        label="What is your share of the property?"
        onChangeText={onThirdHousePropertyShareChange}
        value={thirdHousePropertyShare}
        guide="Enter 50% when property is jointly owned with your ex-partner. Select 100% when property is only owned by you. Enter an appropriate % if another person has a percentage interest."
        required={true}
        id="thirdHourPropertyShare"
        name="thirdHourPropertyShare"
      />
    </>
  );
};

export default YourThirdHouse;
