import React, { useState } from "react";
import SelectInput from "../FormComponents/Components/SelectInput";
import useScrollTo from "../hooks/useScrollTo";
import YesNoInput from "../FormComponents/Components/YesNoInput";

const caseOptions = ["Child Arrangements", "Financial Arrangements", "Both"];

const YourCases = ({ state, dispatch }) => {
  const [caseAbout, setCaseAbout] = useState(state.caseAbout || "select");
  const [financialIssues, setFinancialIssues] = useState(state.financialIssues);
  const [otherPartyProperty, setOtherPartyProperty] = useState(
    state.otherPartyProperty
  );

  useScrollTo(0, 0, "smooth");

  const onCaseAboutChange = ({ target }) => {
    setCaseAbout(target.value);
    dispatch({ type: "CASE_ABOUT", payload: target.value });
  };
  const onFinancialIssuesChange = ({ target }) => {
    setFinancialIssues(target.value);
    dispatch({ type: "FINANCIAL_ISSUE", payload: target.value });
  };
  const onOtherPartyProperty = ({ target }) => {
    setOtherPartyProperty(target.value);
    dispatch({ type: "OTHER_PARTY_PROPERTY", payload: target.value });
  };

  return (
    <>
      <h2>Your case</h2>
      <p>
        We need to know a little about why you are coming to family mediation.
      </p>
      <SelectInput
        label="What is your case about?"
        required={true}
        optionValue={caseAbout}
        onOptionChange={onCaseAboutChange}
        options={caseOptions}
      />
      <YesNoInput
        label="Are you going to discuss financial issues such as family home and savings?"
        required={true}
        checkedTerm={financialIssues}
        onOptionChange={onFinancialIssuesChange}
        name="financialIssues"
        noLabel="No"
        yesLabel="Yes"
        noId="no-financial-issues"
        yesId="yes-financial-issues"
        guide="(This does NOT include CHILD Maintenance payments)"
      />
      <YesNoInput
        label="Do you want to discuss in mediation  PROPERTY & FINANCES that the other party owns?"
        guide="(This does NOT include CHILD Maintenance payments)"
        checkedTerm={otherPartyProperty}
        onOptionChange={onOtherPartyProperty}
        name="otherPartyProperty"
        noLabel="No"
        yesLabel="Yes"
        noId="no-other-party-property"
        yesId="yes-other-party-property"
      />
    </>
  );
};

export default YourCases;
