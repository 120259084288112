import React, { useState } from "react";
import TextInput from "../FormComponents/Components/TextInput";
import useScrollTo from "../hooks/useScrollTo";
import YesNoInput from "../FormComponents/Components/YesNoInput";
import SelectInput from "../FormComponents/Components/SelectInput";

const mediationTypesOptions = [
  "Child arrangements",
  "Financial arrangements",
  "Both issues",
];

const YourPreviousRelationshipDetails = ({ state, dispatch }) => {
  const [otherPersonSurname, setOtherPersonSurname] = useState(
    state.otherPersonSurname || ""
  );
  const [marriedCheck, setMarriedCheck] = useState(state.marriedCheck);
  const [mediationBefore, setMediationBefore] = useState(state.mediationBefore);
  const [mediationTypes, setMediationTypes] = useState(
    state.mediationTypes || "select"
  );

  useScrollTo(0, 0, "smooth");

  const onOtherPersonSurnameChange = ({ target }) => {
    setOtherPersonSurname(target.value);
    dispatch({ type: "OTHER_PERSON_SURNAME", payload: target.value });
  };
  const onMarriedCheck = ({ target }) => {
    setMarriedCheck(target.value);
    dispatch({ type: "MARRIED_CHECK", payload: target.value });
  };
  const onMediationBeforeChange = ({ target }) => {
    setMediationBefore(target.value);
    dispatch({ type: "MEDIATION_BEFORE", payload: target.value });
  };
  const onMediationTypesChange = ({ target }) => {
    setMediationTypes(target.value);
    dispatch({ type: "MEDIATION_TYPES", payload: target.value });
  };

  return (
    <>
      <h2>Your previous relationship's details</h2>
      <TextInput
        label="What is the surname of the other person in the conflict?"
        value={otherPersonSurname}
        onChangeText={onOtherPersonSurnameChange}
        required={true}
      />
      <YesNoInput
        checkedTerm={marriedCheck}
        onOptionChange={onMarriedCheck}
        label="Were you married with the other person in the conflict?"
        noLabel="No"
        yesLabel="Yes"
        noId="no-married-check"
        yesId="yes-married-check"
        required={true}
        name="married-check"
      />
      <YesNoInput
        checkedTerm={mediationBefore}
        onOptionChange={onMediationBeforeChange}
        label="Have you been in mediation before?"
        noLabel="No"
        yesLabel="Yes"
        noId="no-mediation-before"
        yesId="yes-mediation-before"
        required={true}
        name="mediation-before"
      />
      {mediationBefore === "Yes" && (
        <SelectInput
          label="What type of mediation did you have?"
          options={mediationTypesOptions}
          optionValue={mediationTypes}
          onOptionChange={onMediationTypesChange}
          required={true}
        />
      )}
    </>
  );
};

export default YourPreviousRelationshipDetails;
