export const reducer = (state, { type, payload }) => {
  // console.log({ state: state, type: type, payload: payload });

  switch (type) {
    case "I_CONSENT":
      return { ...state, iConsent: payload };
    case "BENEFIT":
      return { ...state, benefit: payload };
    case "UNIVERSAL_Check":
      return { ...state, universalCheckedValue: payload };
    case "INCOME_Check":
      return { ...state, incomeSupportValue: payload };
    case "JOB_Check":
      return { ...state, jobSeekersCheckedValue: payload };
    case "EMPLOYMENT_Check":
      return { ...state, employmentCheckedValue: payload };
    case "FIRST_NAME":
      return { ...state, firstName: payload };
    case "SECOND_NAME":
      return { ...state, familyName: payload };
    case "FAMILY_NAME":
      return { ...state, differentFamilyName: payload };
    case "BIRTH_FAMILY_NAME":
      return { ...state, birthFamilyName: payload };
    case "BIRTH_DOB":
      return { ...state, dob: payload };
    case "STREET":
      return { ...state, street: payload };
    case "CITY":
      return { ...state, city: payload };
    case "POSTCODE":
      return { ...state, postCode: payload };
    case "COUNTRY":
      return { ...state, country: payload };
    case "INSURANCE":
      return { ...state, insuranceNumber: payload };
    case "PHONE":
      return { ...state, phone: payload };
    case "EMAIL":
      return { ...state, email: payload };
    case "CONFIRM_EMAIL":
      return { ...state, confirmEmail: payload };
    case "FIND":
      return { ...state, find: payload };
    case "STATUS":
      return { ...state, maritalStatus: payload };
    case "PARTNER":
      return { ...state, currentPartner: payload };
    case "CASE_ABOUT":
      return { ...state, caseAbout: payload };
    case "FINANCIAL_ISSUE":
      return { ...state, financialIssues: payload };
    case "OTHER_PARTY_PROPERTY":
      return { ...state, otherPartyProperty: payload };
    case "PROPERTY":
      return { ...state, property: payload };
    case "ACCOMMODATION_LIVE":
      return { ...state, accommodationTypeLive: payload };
    case "MORTGAGE_PAY":
      return { ...state, mortgagePay: payload };
    case "ACCOMMODATION_RENT":
      return { ...state, accommodationTypeRent: payload };
    case "RENT_PAY":
      return { ...state, rentPay: payload };
    case "PROPERTY_WORTH":
      return { ...state, propertyWorth: payload };
    case "MORTGAGE_PROPERTY":
      return { ...state, mortgageProperty: payload };
    case "OUTSTANDING_MORTGAGE":
      return { ...state, outstandingMortgage: payload };
    case "PROPERTY_SHARE":
      return { ...state, propertyShare: payload };
    case "DISCUSSIONS_PROPERTY":
      return { ...state, discussionsProperty: payload };
    case "SECOND_PROPERTY_CHECK":
      return { ...state, secondPropertyCheck: payload };
    case "RELATED_DISCUSS":
      return { ...state, relatedDiscussions: payload };
    case "Saving_Amount":
      return { ...state, savingAmount: payload };
    case "Investment_Discussions":
      return { ...state, investmentDiscussions: payload };
    case "Investments_Amount":
      return { ...state, investmentsAmount: payload };
    case "Valuable_Items":
      return { ...state, valuableItems: payload };
    case "Valuable_Items_Amount":
      return { ...state, valuableItemsAmount: payload };
    case "Pensions_Discussions":
      return { ...state, pensionsDiscussions: payload };
    case "Pensions_Value":
      return { ...state, pensionsValue: payload };
    case "OtherPartyPensions_Value":
      return { ...state, otherPartyPensionsValue: payload };
    case "SECOND_HOUSE":
      return { ...state, secondHouse: payload };
    case "MORTGAGE_OUTSTANDING":
      return { ...state, mortgageOutstanding: payload };
    case "JOINT_NAMES":
      return { ...state, jointNames: payload };
    case "SECOND_HOUSE_PROPERTY_SHARE":
      return { ...state, secondHousePropertyShare: payload };
    case "THIRD_HOUSE_CHECK":
      return { ...state, thirdPropertyCheck: payload };
    case "THIRD_HOUSE":
      return { ...state, thirdHouse: payload };
    case "MORTGAGE_OUTSTANDING_THIRD_HOUSE":
      return { ...state, mortgageOutstandingThirdHouse: payload };
    case "JOINT_NAMES_THIRD_HOUSE":
      return { ...state, jointNamesThirdHouse: payload };
    case "THIRD_HOUSE_PROPERTY_SHARE":
      return { ...state, thirdHousePropertyShare: payload };
    case "SAVE_MONEY":
      return { ...state, savingMoneyAmount: payload };
    case "INVESTMENT_MONEY":
      return { ...state, investmentMoneyAmount: payload };
    case "VALUABLE_ITEMS":
      return { ...state, valuableItemsWorth: payload };
    case "CAPITAL_VALUE":
      return { ...state, capitalValue: payload };
    case "SAVE_MONEY_OTHER_PARTY":
      return { ...state, savingMoneyAmountOtherParty: payload };
    case "INVESTMENT_MONEY_OTHER_PARTY":
      return { ...state, investmentMoneyAmountOtherParty: payload };
    case "VALUABLE_ITEMS_OTHER_PARTY":
      return { ...state, valuableItemsWorthOtherParty: payload };
    case "CAPITAL_VALUE_OTHER_PARTY":
      return { ...state, capitalValueOtherParty: payload };
    case "OTHER_PERSON_SURNAME":
      return { ...state, otherPersonSurname: payload };
    case "MARRIED_CHECK":
      return { ...state, marriedCheck: payload };
    case "MEDIATION_BEFORE":
      return { ...state, mediationBefore: payload };
    case "MEDIATION_TYPES":
      return { ...state, mediationTypes: payload };
    case "INFORMATION_CHECK":
      return { ...state, informationCheck: payload };
    case "REFUSAL_INFO":
      return { ...state, relatedDiscussionsUsed: payload };
    case "REFUSAL_DISCUSSION":
      return { ...state, refusalInformation: payload };
    case "CHECK_ONE":
      return { ...state, checkOne: payload };
    case "CHECK_TWO":
      return { ...state, checkTwo: payload };
    case "CHECK_THREE":
      return { ...state, checkThree: payload };
    case "CHECK_FOUR":
      return { ...state, checkFour: payload };
    case "CHECK_FIVE":
      return { ...state, checkFive: payload };
    case "CHECK_SIX":
      return { ...state, checkSix: payload };
    case "CHECK_SEVEN":
      return { ...state, checkSeven: payload };
    case "CHECK_EIGHT":
      return { ...state, checkEight: payload };
    case "CHECK_NINE":
      return { ...state, checkNine: payload };
    case "EVIDENCE_ONE":
      return { ...state, evidenceOne: payload };
    case "EVIDENCE_TWO":
      return { ...state, evidenceTwo: payload };
    case "EVIDENCE_THREE":
      return { ...state, evidenceThree: payload };
    case "EVIDENCE_FOUR":
      return { ...state, evidenceFour: payload };
    case "EVIDENCE_FIVE":
      return { ...state, evidenceFive: payload };
    case "EVIDENCE_SIX":
      return { ...state, evidenceSix: payload };
    case "EVIDENCE_SEVEN":
      return { ...state, evidenceSeven: payload };
    case "EVIDENCE_EIGHT":
      return { ...state, evidenceEight: payload };
    case "EVIDENCE_NINE":
      return { ...state, evidenceNine: payload };
    case "EVIDENCE_TEN":
      return { ...state, evidenceTen: payload };
    case "URL":
      return { ...state, url: payload };
    default:
      return state;
  }
};

export const defaultState = {
  iConsent: "I consent",
  benefit: "select",
  maritalStatus: "select",
  find: "select",
  caseAbout: "select",
  accommodationTypeLive: "select",
  accommodationTypeRent: "select",
  mediationTypes: "select",
  country: "United Kingdom",
};
