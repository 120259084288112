import { passportPatch } from "../Logic/passportPatch";
import { client } from "../axios/axios";

export const uploadFileToDrive = (id, formStatePassport, setRedirect) => {
  const formData = new FormData();
  formData.append("files", formStatePassport.evidenceOne);
  formData.append("files", formStatePassport.evidenceTwo);
  formData.append("files", formStatePassport.evidenceThree);
  formData.append("files", formStatePassport.evidenceFour);
  formData.append("files", formStatePassport.evidenceFive);
  formData.append("files", formStatePassport.evidenceSix);
  formData.append("files", formStatePassport.evidenceSeven);
  formData.append("files", formStatePassport.evidenceEight);
  formData.append("files", formStatePassport.evidenceNine);
  formData.append("files", formStatePassport.evidenceTen);
  client
    .post(`/uploadFiles/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(() => {
      setRedirect(true);
      localStorage.removeItem(`formStatePassport-${id}`);
    })
    .catch((err) => {
      console.log({ err });
    });
};

export const onBackHandler = (
  e,
  back,
  currentStepIndex,
  formStatePassport,
  goTo
) => {
  e.preventDefault();
  back();
  if (currentStepIndex === 10) {
    if (
      formStatePassport.property !== "Yes" &&
      formStatePassport.financialIssues !== "Yes" &&
      formStatePassport.otherPartyProperty !== "Yes"
    ) {
      goTo(5);
    } else if (formStatePassport.financialIssues === "No") {
      goTo(6);
    } else if (formStatePassport.secondPropertyCheck === "Yes") {
      goTo(8);
    } else if (formStatePassport.thirdPropertyCheck === "Yes") {
      goTo(9);
    } else if (
      formStatePassport.financialIssues === "Yes" &&
      formStatePassport.otherPartyProperty === "Yes"
    ) {
      goTo(7);
    } else if (
      formStatePassport.financialIssues === "No" ||
      formStatePassport.otherPartyProperty === "No"
    ) {
      goTo(6);
    } else if (formStatePassport.property === "Yes") {
      goTo(6);
    } else if (
      formStatePassport.property === "Yes" ||
      formStatePassport.financialIssues === "Yes" ||
      formStatePassport.otherPartyProperty === "Yes"
    ) {
      goTo(6);
    }
  }
  if (currentStepIndex === 8) {
    if (
      formStatePassport.financialIssues === "Yes" &&
      formStatePassport.otherPartyProperty === "Yes"
    ) {
      goTo(7);
    } else if (
      formStatePassport.property !== "Yes" &&
      formStatePassport.financialIssues !== "Yes" &&
      formStatePassport.otherPartyProperty !== "Yes"
    ) {
      goTo(5);
    } else {
      goTo(6);
    }
  }
  if (currentStepIndex === 7) {
    if (formStatePassport.property !== "Yes") {
      goTo(5);
    }
  }
  if (currentStepIndex === 12) {
    if (
      formStatePassport.maritalStatus === "Cohabiting with new partner" ||
      formStatePassport.maritalStatus === "Married to a new partner"
    ) {
      goTo(11);
    } else {
      goTo(10);
    }
  }
};

export const onSubmitHandler = (
  e,
  next,
  currentStepIndex,
  formStatePassport,
  goTo,
  steps,
  setIsSubmitted,
  pathname,
  id,
  setRedirect
) => {
  e.preventDefault();
  next();

  if (currentStepIndex === 5) {
    if (
      formStatePassport.property !== "Yes" &&
      formStatePassport.financialIssues !== "Yes" &&
      formStatePassport.otherPartyProperty !== "Yes"
    ) {
      goTo(10);
    } else if (formStatePassport.property === "Yes") {
      goTo(6);
    } else if (
      formStatePassport.financialIssues === "Yes" &&
      formStatePassport.otherPartyProperty === "Yes"
    ) {
      goTo(7);
    }
  }
  if (currentStepIndex === 6) {
    if (
      formStatePassport.financialIssues === "Yes" &&
      formStatePassport.property === "Yes" &&
      formStatePassport.secondPropertyCheck === "Yes"
    ) {
      goTo(8);
    } else if (
      formStatePassport.financialIssues === "Yes" &&
      formStatePassport.secondPropertyCheck === "No"
    ) {
      goTo(10);
    }
    if (
      formStatePassport.financialIssues === "Yes" &&
      formStatePassport.otherPartyProperty === "Yes"
    ) {
      goTo(7);
    } else {
      goTo(10);
    }
  }
  if (currentStepIndex === 7) {
    if (
      formStatePassport.financialIssues === "Yes" &&
      formStatePassport.secondPropertyCheck === "Yes"
    ) {
      goTo(8);
    } else if (formStatePassport.financialIssues === "No") {
      goTo(10);
    } else if (
      formStatePassport.financialIssues === "Yes" &&
      formStatePassport.secondPropertyCheck === "No"
    ) {
      goTo(10);
    } else if (formStatePassport.property === "No") {
      goTo(10);
    }
  }
  if (currentStepIndex === 8) {
    if (formStatePassport.thirdPropertyCheck === "Yes") {
      goTo(9);
    } else {
      goTo(10);
    }
  }
  if (currentStepIndex === 10) {
    if (
      (formStatePassport.maritalStatus === "Cohabiting with new partner" ||
        formStatePassport.maritalStatus === "Married to a new partner") &&
      formStatePassport.currentPartner === "Yes"
    ) {
      goTo(11);
    } else if (
      formStatePassport.maritalStatus !== "Cohabiting with new partner" ||
      formStatePassport.maritalStatus !== "Married to a new partner"
    ) {
      goTo(12);
    }
  }
  if (currentStepIndex + 1 === steps.length) {
    passportPatch(formStatePassport, id, setIsSubmitted, setRedirect);
  }
};
