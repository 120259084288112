import React, { useState } from "react";

import useScrollTo from "../hooks/useScrollTo";

import TextInput from "../FormComponents/Components/TextInput";
import YesNoInput from "../FormComponents/Components/YesNoInput";

const TheProperty = ({ state, dispatch }) => {
  const [propertyWorth, setPropertyWorth] = useState(state.propertyWorth || "");
  const [mortgageProperty, setMortgageProperty] = useState(
    state.mortgageProperty || ""
  );
  const [outstandingMortgage, setOutstandingMortgage] = useState(
    state.outstandingMortgage
  );
  const [propertyShare, setPropertyShare] = useState(state.propertyShare || "");
  const [discussionsProperty, setDiscussionsProperty] = useState(
    state.discussionsProperty
  );
  const [secondPropertyCheck, setSecondPropertyCheck] = useState(
    state.secondPropertyCheck
  );

  useScrollTo(0, 0, "smooth");

  const onPropertyWorthChange = ({ target }) => {
    setPropertyWorth(target.value);
    dispatch({ type: "PROPERTY_WORTH", payload: target.value });
  };
  const onMortgagePropertyChange = ({ target }) => {
    setMortgageProperty(target.value);
    dispatch({ type: "MORTGAGE_PROPERTY", payload: target.value });
  };
  const onOutstandingMortgagePropertyChange = ({ target }) => {
    setOutstandingMortgage(target.value);
    dispatch({ type: "OUTSTANDING_MORTGAGE", payload: target.value });
  };
  const onPropertyShareChange = ({ target }) => {
    setPropertyShare(target.value);
    dispatch({ type: "PROPERTY_SHARE", payload: target.value });
  };
  const onDiscussionsPropertyChange = ({ target }) => {
    setDiscussionsProperty(target.value);
    dispatch({ type: "DISCUSSIONS_PROPERTY", payload: target.value });
  };
  const onSecondPropertyCheck = ({ target }) => {
    setSecondPropertyCheck(target.value);
    dispatch({ type: "SECOND_PROPERTY_CHECK", payload: target.value });
  };

  return (
    <>
      <h2>The property</h2>
      <TextInput
        label="What do you think the property is worth according to the deeds?"
        required={true}
        value={propertyWorth}
        onChangeText={onPropertyWorthChange}
        id="propertyWorth"
      />
      <TextInput
        label="What is the outstanding mortgage in the property?"
        required={true}
        value={mortgageProperty}
        onChangeText={onMortgagePropertyChange}
        id="mortgageProperty"
        guide="Please add the value in British pounds (£)"
      />
      <YesNoInput
        label="Is property in joint names with your ex-partner?"
        required={true}
        noLabel="No"
        yesLabel="Yes"
        noId="no-joint-name"
        yesId="yes-joint-name"
        checkedTerm={outstandingMortgage}
        onOptionChange={onOutstandingMortgagePropertyChange}
        name="joint-name"
      />
      <TextInput
        label="What is your share of the property?"
        required={true}
        guide="Enter 50% when property is jointly owned with your ex-partner. Select 100% when property is only owned by you. Enter an appropriate % if another person has a percentage interest."
        id="propertyShare"
        name="propertyShare"
        onChangeText={onPropertyShareChange}
        value={propertyShare}
      />
      <YesNoInput
        label="Are you going to have discussions in mediation about this property?"
        yesLabel="Yes"
        yesId="yes-discussion"
        noLabel="No"
        noId="no-discussion"
        checkedTerm={discussionsProperty}
        onOptionChange={onDiscussionsPropertyChange}
        required={true}
      />
      {state.financialIssues === "Yes" && (
        <YesNoInput
          label="Do you own a second property?"
          yesLabel="Yes"
          noLabel="No"
          yesId="yes-second-property"
          noId="no-second-property"
          checkedTerm={secondPropertyCheck}
          onOptionChange={onSecondPropertyCheck}
          required={true}
        />
      )}
    </>
  );
};

export default TheProperty;
