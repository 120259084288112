import React, { useState } from "react";
import YesNoInput from "../FormComponents/Components/YesNoInput";
import useScrollTo from "../hooks/useScrollTo";

const FirstPage = ({ state, dispatch }) => {
  const [iConsent, setIConsent] = useState(state.iConsent);

  useScrollTo(0, 0, "smooth");

  const onBenefitOptionChange = ({ target }) => {
    setIConsent(target.value);
    dispatch({ type: "I_CONSENT", payload: target.value });
  };

  return (
    <>
      <div className="page-one-header">
        <div className="headers-colors">
          <h1 className="text-center">Passporting Benefits</h1>
          <h2 className="text-center">Your Legal Aid Assessment</h2>
        </div>
        <p>
          This includes Universal Credit, Income Support, Employment and Support
          Allowance (Income Related) and Jobseeker's Allowance.
        </p>
      </div>
      <div className="page-one-header page-one">
        <fieldset>
          <legend>Before we start</legend>
          As part of our commitment to your individual rights and freedoms under
          data privacy laws, we need to ask for your consent to collect and use
          the information you provide to us in this form. The information you
          provide in this form will be collected, processed, and used by us in
          accordance with our{" "}
          <a
            href="https://www.directmediationservices.co.uk/wp-content/uploads/2022/09/Privacy-Policy-v-1.1.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          .
        </fieldset>
        <br />
        <p>
          For your information legal aid assessments could take up to seven
          days, depending on the complexity of your application.
        </p>
      </div>
      <YesNoInput
        label='By selecting "I consent" below, you confirm that you consent to our collection and use of your personal information.'
        yesLabel="I consent"
        noLabel="I do not consent"
        noId="no-i-consent"
        yesId="yes-i-consent"
        name="i-consent"
        required={true}
        checkedTerm={iConsent}
        onOptionChange={onBenefitOptionChange}
      />
      <p>
        After completing each field, please click on the <strong>"Next"</strong>{" "}
        button to continue filling the form.
      </p>
    </>
  );
};

export default FirstPage;
