import React, { useState } from "react";
import TextInput from "../FormComponents/Components/TextInput";
import useScrollTo from "../hooks/useScrollTo";
import YesNoInput from "../FormComponents/Components/YesNoInput";

const YourSecondHouse = ({ state, dispatch }) => {
  const [secondHouse, setSecondHouse] = useState(state.secondHouse || "");
  const [mortgageOutstanding, setMortgageOutstanding] = useState(
    state.mortgageOutstanding || ""
  );
  const [jointNames, setJointNames] = useState(state.jointNames);
  const [secondHousePropertyShare, setSecondHousePropertyShare] = useState(
    state.secondHousePropertyShare || ""
  );
  const [thirdPropertyCheck, setThirdPropertyCheck] = useState(
    state.thirdPropertyCheck
  );

  useScrollTo(0, 0, "smooth");

  const onSecondHouseProperty = ({ target }) => {
    setSecondHouse(target.value);
    dispatch({ type: "SECOND_HOUSE", payload: target.value });
  };
  const onMortgageOutstandingChange = ({ target }) => {
    setMortgageOutstanding(target.value);
    dispatch({ type: "MORTGAGE_OUTSTANDING", payload: target.value });
  };
  const onJointNamesChange = ({ target }) => {
    setJointNames(target.value);
    dispatch({ type: "JOINT_NAMES", payload: target.value });
  };
  const onSecondHousePropertyShareChange = ({ target }) => {
    setSecondHousePropertyShare(target.value);
    dispatch({ type: "SECOND_HOUSE_PROPERTY_SHARE", payload: target.value });
  };
  const onThirdHouseProperty = ({ target }) => {
    setThirdPropertyCheck(target.value);
    dispatch({ type: "THIRD_HOUSE_CHECK", payload: target.value });
  };

  return (
    <>
      <h2>Your Second House</h2>
      <TextInput
        label="What do you think your second house is worth according to the deeds?"
        id="second_house"
        value={secondHouse}
        onChangeText={onSecondHouseProperty}
        required={true}
      />
      <TextInput
        label="What is your outstanding mortgage?"
        id="outstanding_mortgage_house"
        value={mortgageOutstanding}
        onChangeText={onMortgageOutstandingChange}
        required={true}
      />
      <YesNoInput
        label="Is property in joint names with your ex-partner?"
        noLabel="No"
        yesLabel="Yes"
        noId="no-joint"
        yesId="yes-joint"
        required={true}
        checkedTerm={jointNames}
        onOptionChange={onJointNamesChange}
      />
      <TextInput
        label="What is your share of the property?"
        id="secondHousePropertyShare"
        name="secondHousePropertyShare"
        required={true}
        onChangeText={onSecondHousePropertyShareChange}
        value={secondHousePropertyShare}
        guide="Enter 50% when property is jointly owned with your ex-partner. Select 100% when property is only owned by you. Enter an appropriate % if another person has a percentage interest."
      />
      <YesNoInput
        label="Do you own a third property?"
        yesLabel="Yes"
        noLabel="No"
        noId="no-third-property"
        yesId="yes-third-property"
        required={true}
        checkedTerm={thirdPropertyCheck}
        onOptionChange={onThirdHouseProperty}
      />
    </>
  );
};

export default YourSecondHouse;
