import React from "react";

const YouTubeSection = ({
  title,
  code,
  checkedId,
  checkedValue,
  onCheckChange,
}) => {
  return (
    <>
      <h3 className="text-center">How to submit your application.</h3>
      <div className="youtube-section">
        <h2 className="text-center">{title}</h2>
        <p>
          Please watch this short cartoon video explaining what evidence you
          need before making the online application.{" "}
          <strong>It is important you watch it.</strong>
        </p>
        <div className="video-responsive m-25">
          <iframe
            width="853"
            height="480"
            src={`https://www.youtube.com/embed/${code}`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </div>
        <div className="check-box">
          <input
            type="checkbox"
            checked={checkedValue}
            onChange={onCheckChange}
            id={checkedId}
            required
          />
          <label htmlFor={checkedId}>
            Please confirm that you have watched this video and you have the
            required evidence.
          </label>
        </div>
      </div>
    </>
  );
};

export default YouTubeSection;
