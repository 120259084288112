import React, { useMemo, useState } from "react";
import countryList from "react-select-country-list";

import TextInput from "../FormComponents/Components/TextInput";
import useScrollTo from "../hooks/useScrollTo";
import YesNoInput from "../FormComponents/Components/YesNoInput";
import SelectInput from "../FormComponents/Components/SelectInput";

const findOptions = [
  "Google",
  "Support Through Court",
  "Legal Aid website",
  "Solicitor",
  "Mediator - Rachel Marsden",
  "FMC website",
  "Community Centre",
  "Facebook",
  "Friend/Family",
];

const maritalStatusOptions = [
  "Married to the other party in the conflict",
  "Civil partnered to the other party in the conflict",
  "Divorced from the other party in the conflict",
  "Separated from the other party in the conflict",
  "Single",
  "Cohabiting with new partner",
  "Married to a new partner",
];

const PersonalDetails = ({ state, dispatch }) => {
  const options = useMemo(() => countryList().getData(), []);
  let countries = [];
  options.map((option) => {
    countries.push(option.label);
    return countries;
  });

  const [firstName, setFirstName] = useState(state.firstName || "");
  const [familyName, setFamilyName] = useState(state.familyName || "");
  const [differentFamilyName, setDifferentFamilyName] = useState(
    state.differentFamilyName
  );
  const [birthFamilyName, setBirthFamilyName] = useState(
    state.birthFamilyName || ""
  );
  const [dob, setDob] = useState(state.dob || "");
  const [street, setStreet] = useState(state.street || "");
  const [city, setCity] = useState(state.city || "");
  const [postCode, setPostCode] = useState(state.postCode || "");
  const [country, setCountry] = useState(state.country || "United Kingdom");
  const [insuranceNumber, setInsuranceNumber] = useState(
    state.insuranceNumber || ""
  );
  const [phone, setPhone] = useState(state.phone || "");
  const [email, setEmail] = useState(state.email || "");
  const [confirmEmail, setConfirmEmail] = useState(state.confirmEmail || "");
  const [find, setFind] = useState(state.find || "select");
  const [maritalStatus, setMaritalStatus] = useState(
    state.maritalStatus || "select"
  );
  const [currentPartner, setCurrentPartner] = useState(state.currentPartner);

  useScrollTo(0, 0, "smooth");

  const onFirstNameChange = ({ target }) => {
    setFirstName(target.value);
    dispatch({ type: "FIRST_NAME", payload: target.value });
  };
  const onSecondNameChange = ({ target }) => {
    setFamilyName(target.value);
    dispatch({ type: "SECOND_NAME", payload: target.value });
  };
  const onDifferentFamilyNameChange = ({ target }) => {
    setDifferentFamilyName(target.value);
    dispatch({ type: "FAMILY_NAME", payload: target.value });
  };
  const onBirthFamilyNameChange = ({ target }) => {
    setBirthFamilyName(target.value);
    dispatch({ type: "BIRTH_FAMILY_NAME", payload: target.value });
  };
  const onDobChange = ({ target }) => {
    setDob(target.value);
    dispatch({ type: "BIRTH_DOB", payload: target.value });
  };
  const onStreetChange = ({ target }) => {
    setStreet(target.value);
    dispatch({ type: "STREET", payload: target.value });
  };
  const onCityChange = ({ target }) => {
    setCity(target.value);
    dispatch({ type: "CITY", payload: target.value });
  };
  const onPostCodeChange = ({ target }) => {
    setPostCode(target.value);
    dispatch({ type: "POSTCODE", payload: target.value });
  };
  const onCountryChange = ({ target }) => {
    setCountry(target.value);
    dispatch({ type: "COUNTRY", payload: target.value });
  };
  const onInsuranceNumberChange = ({ target }) => {
    setInsuranceNumber(target.value);
    dispatch({ type: "INSURANCE", payload: target.value });
  };
  const onPhoneChange = ({ target }) => {
    setPhone(target.value);
    dispatch({ type: "PHONE", payload: target.value });
  };
  const onEmailChange = ({ target }) => {
    setEmail(target.value);
    dispatch({ type: "EMAIL", payload: target.value });
  };
  const onConfirmEmailChange = ({ target }) => {
    setConfirmEmail(target.value);
    dispatch({ type: "CONFIRM_EMAIL", payload: target.value });
  };
  const onFindChange = ({ target }) => {
    setFind(target.value);
    dispatch({ type: "FIND", payload: target.value });
  };
  const onMaritalStatusChanged = ({ target }) => {
    setMaritalStatus(target.value);
    dispatch({ type: "STATUS", payload: target.value });
  };
  const onCurrentPartnerChange = ({ target }) => {
    setCurrentPartner(target.value);
    dispatch({ type: "PARTNER", payload: target.value });
  };

  return (
    <>
      <h2>Your personal details</h2>
      <p className="guide-label">
        Don’t worry we don’t give your information to anyone, only the Legal Aid
        Agency. This is a confidential process.
      </p>
      <div className="big-screen-layout">
        <TextInput
          label="What is your first name?"
          required={true}
          id="firstName"
          value={firstName}
          onChangeText={onFirstNameChange}
        />
        <TextInput
          label="What is your surname/family name?"
          required={true}
          value={familyName}
          onChangeText={onSecondNameChange}
        />
      </div>
      <YesNoInput
        label="Was your surname/family name different at birth?"
        required={true}
        name="different-family-name"
        checkedTerm={differentFamilyName}
        onOptionChange={onDifferentFamilyNameChange}
        noLabel="No"
        yesLabel="Yes"
        noId="no-different-family-name"
        yesId="yes-different-family-name"
      />
      {differentFamilyName === "Yes" && (
        <TextInput
          label="What was your surname/family name at birth?"
          required={true}
          value={birthFamilyName}
          onChangeText={onBirthFamilyNameChange}
          id="birthFamilyName"
        />
      )}
      <div className="form-control colum-direction">
        <label htmlFor="date-of-birth">
          What is your date of birth?<span className="color-red">*</span>
        </label>
        <input
          type="date"
          name="dob"
          id="date-of-birth"
          value={dob}
          onChange={onDobChange}
          required
          max={new Date().toLocaleDateString("fr-ca")}
        />
      </div>
      <h3 className="text-center">What is your home address?</h3>
      <div className="big-screen-layout">
        <TextInput
          label="House number & Street"
          value={street}
          onChangeText={onStreetChange}
          id="street"
          required={true}
        />
        <TextInput
          label="Town/City"
          value={city}
          onChangeText={onCityChange}
          id="city"
          required={true}
        />
      </div>
      <div className="big-screen-layout">
        <TextInput
          label="Postcode"
          value={postCode}
          onChangeText={onPostCodeChange}
          id="postCode"
          required={true}
        />
        <SelectInput
          label="Country"
          optionValue={country}
          options={countries}
          onOptionChange={onCountryChange}
          required={true}
        />
      </div>

      <div className="big-screen-layout">
        <TextInput
          label="What is your National Insurance Number?"
          required={true}
          value={insuranceNumber}
          onChangeText={onInsuranceNumberChange}
          guide="NINo comprises TWO letters followed by SIX numbers and ONE final letter."
          id="insuranceNumber"
          pattern="^\s*[a-zA-Z]{2}(?:\s*\d\s*){6}[a-zA-Z]?\s*$"
          anotherText="[If you do not know this, we cannot continue.]"
        />
        <TextInput
          label="What is your mobile telephone number?"
          required={true}
          value={phone}
          onChangeText={onPhoneChange}
          guide="Enter your mobile phone number without spaces. Example 07123456789"
          anotherText="This is the number the mediator will call you on."
          pattern="^(07[\d]{8,12}|447[\d]{7,11})$"
          id="phone"
        />
      </div>
      <div className="form-control colum-direction">
        <label htmlFor="email">
          What is your email address?<span className="color-red">*</span>
        </label>
        <input
          type="email"
          name="email"
          id="email"
          required
          value={email}
          onChange={onEmailChange}
        />
      </div>
      {email !== "" && (
        <div className="form-control colum-direction">
          <label htmlFor="confirm-email">
            Confirm email address?<span className="color-red">*</span>
          </label>
          <input
            type="email"
            name="confirm-email"
            id="confirm-email"
            required
            value={confirmEmail}
            onChange={onConfirmEmailChange}
          />
        </div>
      )}
      {email !== confirmEmail && <p className="color-red">Email not match.</p>}
      <SelectInput
        label="How did you find out about Direct Mediation Services?"
        options={findOptions}
        optionValue={find}
        onOptionChange={onFindChange}
        required={true}
        guide="Please choose from the list by clicking on the box below."
      />
      <SelectInput
        label="What is your marital status?"
        options={maritalStatusOptions}
        optionValue={maritalStatus}
        onOptionChange={onMaritalStatusChanged}
        required={true}
        guide="Please choose from the list by clicking on the box below."
      />
      {(maritalStatus === "Cohabiting with new partner" ||
        maritalStatus === "Married to a new partner") && (
        <YesNoInput
          label="Is your current partner listed on the same benefits claim?"
          checkedTerm={currentPartner}
          onOptionChange={onCurrentPartnerChange}
          noLabel="No"
          yesLabel="Yes"
          yesId="yes-currentPartner"
          noId="no-currentPartner"
          required={true}
        />
      )}
      {(maritalStatus === "Cohabiting with new partner" ||
        maritalStatus === "Married to a new partner") &&
        currentPartner === "No" && (
          <div className="danger">
            <p>
              We are sorry, but you are NOT automatically entitled to legal aid
              funding via Passporting Benefits. Your application has not been
              submitted.
              <br />
              Please try the “Low Income” route. You can start your Low-Income
              Application{" "}
              <a
                href="https://laa-low-income.vercel.app/"
                target="_blank"
                rel="noopener noreferrer"
              >
                HERE
              </a>
              .
            </p>
          </div>
        )}
    </>
  );
};

export default PersonalDetails;
