import React from "react";
import { Route, Routes } from "react-router-dom";

import Form from "../FormComponents/Form";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/:id" element={<Form />} />
        <Route path="" element={<Form />} />
      </Routes>
    </>
  );
};

export default App;
