import React, { useState } from "react";
import YesNoInput from "../FormComponents/Components/YesNoInput";
import useScrollTo from "../hooks/useScrollTo";
import SelectInput from "../FormComponents/Components/SelectInput";
import TextInput from "../FormComponents/Components/TextInput";

const accommodationOptions = [
  "Rent",
  "Own",
  "I live with friend or family and I pay rent",
  "I live with friend or family and don't pay rent",
];

const rentOptions = [
  "Privately rented",
  "Housing association",
  "Council property",
  "Sheltered housing",
  "Temporary accommodation",
];

const YourAccommodation = ({ state, dispatch }) => {
  const [property, setProperty] = useState(state.property);
  const [accommodationTypeLive, setAccommodationTypeLive] = useState(
    state.accommodationTypeLive || "select"
  );
  const [mortgagePay, setMortgagePay] = useState(state.mortgagePay || "");
  const [accommodationTypeRent, setAccommodationTypeRent] = useState(
    state.accommodationTypeRent || "select"
  );
  const [rentPay, setRentPay] = useState(state.rentPay || "");

  useScrollTo(0, 0, "smooth");

  const onPropertyChange = ({ target }) => {
    setProperty(target.value);
    dispatch({ type: "PROPERTY", payload: target.value });
  };
  const onAccommodationLiveChange = ({ target }) => {
    setAccommodationTypeLive(target.value);
    dispatch({ type: "ACCOMMODATION_LIVE", payload: target.value });
  };
  const onMortgagePayChange = ({ target }) => {
    setMortgagePay(target.value);
    dispatch({ type: "MORTGAGE_PAY", payload: target.value });
  };
  const onAccommodationRentChange = ({ target }) => {
    setAccommodationTypeRent(target.value);
    dispatch({ type: "ACCOMMODATION_RENT", payload: target.value });
  };
  const onRentPayChange = ({ target }) => {
    setRentPay(target.value);
    dispatch({ type: "RENT_PAY", payload: target.value });
  };

  return (
    <>
      <h2>Your accommodation</h2>
      <YesNoInput
        label="Do you own a property?"
        required={true}
        checkedTerm={property}
        onOptionChange={onPropertyChange}
        name="property"
        noLabel="No"
        yesLabel="Yes"
        noId="no-property"
        yesId="yes-property"
        guide="This includes houses, flat, commercial property"
      />
      <SelectInput
        label="What type of accommodation do you live in?"
        required={true}
        guide="Please choose from the list by clicking on the box below."
        options={accommodationOptions}
        optionValue={accommodationTypeLive}
        onOptionChange={onAccommodationLiveChange}
      />
      {property === "Yes" && (
        <TextInput
          label="How much did you personally pay of your mortgage last month?"
          guide="(Amount in £)"
          value={mortgagePay}
          onChangeText={onMortgagePayChange}
          id="mortgagePay"
          required={true}
        />
      )}
      {accommodationTypeLive === "Rent" && (
        <>
          <SelectInput
            label="What type of accommodation do you rent?"
            guide="Please choose from the list by clicking on the box below."
            options={rentOptions}
            onOptionChange={onAccommodationRentChange}
            optionValue={accommodationTypeRent}
            required={true}
          />
          <TextInput
            label="How much did you personally pay in rent last month?"
            guide="This does not include the amount you receive from Housing benefit."
            value={rentPay}
            onChangeText={onRentPayChange}
            id="rentPay"
            required={true}
          />
        </>
      )}
    </>
  );
};

export default YourAccommodation;
