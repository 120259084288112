import { useEffect } from "react";

const Submitted = ({ redirect }) => {
  useEffect(() => {
    if (redirect) {
      window.location.replace("https://www.directmediationservices.co.uk/");
    }
  }, [redirect]); // Empty dependency array ensures this effect runs only once on component mount

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "25px",
          borderRadius: "22px",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          textAlign: "center",
        }}
      >
        <h1>Thanks! We have received your submission.</h1>
        <p>Please Wait until redirect.</p>
      </div>
    </div>
  );
};

export default Submitted;
