import React from "react";
import { onBackHandler, onSubmitHandler } from "../functions/navFunctions";
import { useLocation, useParams } from "react-router-dom";

const FormComponent = ({
  logo,
  currentStepIndex,
  step,
  formStatePassport,
  isFirstStep,
  steps,
  isLastStep,
  next,
  goTo,
  back,
  setIsSubmitted,
  setRedirect,
}) => {
  const { pathname } = useLocation();
  const { id } = useParams();
  return (
    <>
      <div className="full-screen">
        <div className="form-screen">
          <div className="yellow-bar"></div>
          <header>
            <img src={logo} alt="Logo" />
          </header>
          <form
            className="form"
            onSubmit={(e) =>
              onSubmitHandler(
                e,
                next,
                currentStepIndex,
                formStatePassport,
                goTo,
                steps,
                setIsSubmitted,
                pathname,
                id,
                setRedirect
              )
            }
          >
            <div className="steps">
              {currentStepIndex + 1} / {steps.length}
            </div>
            {step}
            {formStatePassport.iConsent !== "I consent" && (
              <div className="danger">
                <h2>Ok. This makes it a little hard to continue...</h2>
                <p>
                  Because you don't agree to our data protection policy, we we
                  are unable to assist you at this time.
                  <strong>Your application won't be submitted.</strong>
                </p>
              </div>
            )}
            {currentStepIndex === 13 && !formStatePassport.url && (
              <p className="color-red">Please click save</p>
            )}
            <div>
              <div className="submit-btn submit">
                {!isFirstStep && (
                  <button
                    type="button"
                    className="back-btn"
                    onClick={(e) =>
                      onBackHandler(
                        e,
                        back,
                        currentStepIndex,
                        formStatePassport,
                        goTo
                      )
                    }
                  >
                    Back
                  </button>
                )}
                <button
                  type="submit"
                  className="next-btn"
                  disabled={
                    (currentStepIndex === 3 &&
                      (formStatePassport.maritalStatus ===
                        "Cohabiting with new partner" ||
                        formStatePassport.maritalStatus ===
                          "Married to a new partner") &&
                      formStatePassport.currentPartner === "No") ||
                    (currentStepIndex === 0 &&
                      formStatePassport.iConsent !== "I consent") ||
                    (currentStepIndex === 1 &&
                      formStatePassport.benefit === "select") ||
                    (currentStepIndex === 3 &&
                      (formStatePassport.find === "select" ||
                        formStatePassport.maritalStatus === "select" ||
                        formStatePassport.email !==
                          formStatePassport.confirmEmail)) ||
                    (currentStepIndex === 4 &&
                      formStatePassport.caseAbout === "select") ||
                    (currentStepIndex === 5 &&
                      formStatePassport.accommodationTypeLive === "select") ||
                    (currentStepIndex === 12 &&
                      formStatePassport.mediationTypes === "select" &&
                      formStatePassport.mediationBefore === "Yes") ||
                    (currentStepIndex === 13 &&
                      (formStatePassport.informationCheck === "No" ||
                        formStatePassport.relatedDiscussionsUsed === "No" ||
                        formStatePassport.refusalInformation === "No")) ||
                    (currentStepIndex === 13 && !formStatePassport.url)
                  }
                  style={{
                    filter:
                      ((currentStepIndex === 3 &&
                        (formStatePassport.maritalStatus ===
                          "Cohabiting with new partner" ||
                          formStatePassport.maritalStatus ===
                            "Married to a new partner") &&
                        formStatePassport.currentPartner === "No") ||
                        (currentStepIndex === 0 &&
                          formStatePassport.iConsent !== "I consent") ||
                        (currentStepIndex === 1 &&
                          formStatePassport.benefit === "select") ||
                        (currentStepIndex === 3 &&
                          (formStatePassport.find === "select" ||
                            formStatePassport.maritalStatus === "select" ||
                            formStatePassport.email !==
                              formStatePassport.confirmEmail)) ||
                        (currentStepIndex === 4 &&
                          formStatePassport.caseAbout === "select") ||
                        (currentStepIndex === 5 &&
                          (formStatePassport.accommodationTypeLive ===
                            "select" ||
                            (formStatePassport.accommodationTypeLive ===
                              "Rent" &&
                              formStatePassport.accommodationTypeRent ===
                                "select"))) ||
                        (currentStepIndex === 12 &&
                          formStatePassport.mediationTypes === "select" &&
                          formStatePassport.mediationBefore === "Yes") ||
                        (currentStepIndex === 13 &&
                          (formStatePassport.informationCheck === "No" ||
                            formStatePassport.relatedDiscussionsUsed === "No" ||
                            formStatePassport.refusalInformation === "No")) ||
                        (currentStepIndex === 13 && !formStatePassport.url)) &&
                      "opacity(.4)",
                  }}
                >
                  {isLastStep ? "Finish" : "Next"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default FormComponent;
