import React, { useState } from "react";

import SelectInput from "../FormComponents/Components/SelectInput";
import useScrollTo from "../hooks/useScrollTo";
import YouTubeSection from "../FormComponents/YouTubeSection";

const benefitsOptions = [
  "Universal Credit",
  "Income Support",
  "Jobseekers Allowance",
  "Employment and Support Allowance (Income-Related)",
];

const TypeOfApplication = ({ state, dispatch }) => {
  const [benefit, setBenefit] = useState(state.benefit || "select");
  const [universalCheckedValue, setUniversalCheckedValue] = useState(
    state.universalCheckedValue || false
  );
  const [incomeSupportValue, setIncomeSupportValue] = useState(
    state.incomeSupportValue || false
  );
  const [jobSeekersCheckedValue, setJobSeekersCheckedValue] = useState(
    state.jobSeekersCheckedValue || false
  );
  const [employmentCheckedValue, setEmploymentCheckedValue] = useState(
    state.employmentCheckedValue || false
  );

  useScrollTo(0, 0, "smooth");

  const onBenefitOptionChange = ({ target }) => {
    setBenefit(target.value);
    dispatch({ type: "BENEFIT", payload: target.value });
  };

  const onUniversalCheckOptionChange = ({ target }) => {
    setUniversalCheckedValue(target.value);
    dispatch({ type: "UNIVERSAL_Check", payload: target.value });
  };

  const onIncomeCheckOptionChange = ({ target }) => {
    setIncomeSupportValue(target.value);
    dispatch({ type: "INCOME_Check", payload: target.value });
  };

  const onJobSeekersCheckOptionChange = ({ target }) => {
    setJobSeekersCheckedValue(target.value);
    dispatch({ type: "JOB_Check", payload: target.value });
  };

  const onEmploymentCheckOptionChange = ({ target }) => {
    setEmploymentCheckedValue(target.value);
    dispatch({ type: "EMPLOYMENT_Check", payload: target.value });
  };

  return (
    <>
      <h2>Type of application</h2>
      <SelectInput
        label="Which benefit are you currently receiving?"
        options={benefitsOptions}
        required={true}
        optionValue={benefit}
        onOptionChange={onBenefitOptionChange}
      />
      {benefit === "Universal Credit" && (
        <YouTubeSection
          title="Universal Credit"
          code="uiHcjrF8B-U"
          checkedValue={universalCheckedValue}
          onCheckChange={onUniversalCheckOptionChange}
          checkedId="Universal-credit"
        />
      )}
      {(benefit === "Income Support" || benefit === "Jobseekers Allowance") && (
        <YouTubeSection
          title="Income Support/Jobseekers Allowance"
          code="Fb46YeNd0bo"
          checkedValue={incomeSupportValue || jobSeekersCheckedValue}
          checkedId="Income-Support"
          onCheckChange={
            onIncomeCheckOptionChange || onJobSeekersCheckOptionChange
          }
        />
      )}
      {benefit === "Employment and Support Allowance (Income-Related)" && (
        <YouTubeSection
          title="Employment & Support Allowance (Income-Related)"
          code="OCTmnSViz_s"
          checkedValue={employmentCheckedValue}
          checkedId="Employment"
          onCheckChange={onEmploymentCheckOptionChange}
        />
      )}
    </>
  );
};

export default TypeOfApplication;
