import React, { useEffect, useReducer, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { useMultiStepForm } from "../hooks/useMultiStepForm";
import { defaultState, reducer } from "../Logic/reducer";

import logo from "../assets/dms-logotype2.png";
import FormComponent from "./FormComponent";

import FirstPage from "../Pages/FirstPage";
import TypeOfApplication from "../Pages/TypeOfApplication";
import PersonalDetails from "../Pages/PersonalDetails";
import YourCases from "../Pages/YourCases";
import YourAccommodation from "../Pages/YourAccommodation";
import TheProperty from "../Pages/TheProperty";
import Assets from "../Pages/Assets";
import YourSecondHouse from "../Pages/YourSecondHouse";
import YourThirdHouse from "../Pages/YourThirdHouse";
import FinancialAssets from "../Pages/FinancialAssets";
import OtherPartyAssets from "../Pages/OtherPartyAssets";
import YourPreviousRelationshipDetails from "../Pages/YourPreviousRelationshipDetails";
import ClientDeclaration from "../Pages/ClientDeclaration";
import Evidence from "../Pages/Evidence";
import Submitted from "../Pages/Submitted";

const Form = () => {
  const isMounted = useRef(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const { id } = useParams();

  const [formStatePassport, dispatch] = useReducer(
    reducer,
    JSON.parse(localStorage.getItem(`formStatePassport-${id}`)) || defaultState
  );

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    localStorage.setItem(
      `formStatePassport-${id}`,
      JSON.stringify(formStatePassport)
    );
  }, [formStatePassport, id]);

  const {
    currentStepIndex,
    steps,
    step,
    isFirstStep,
    isLastStep,
    back,
    next,
    goTo,
  } = useMultiStepForm([
    <FirstPage state={formStatePassport} dispatch={dispatch} />,
    <TypeOfApplication state={formStatePassport} dispatch={dispatch} />,
    <Evidence state={formStatePassport} dispatch={dispatch} />,
    <PersonalDetails state={formStatePassport} dispatch={dispatch} />,
    <YourCases state={formStatePassport} dispatch={dispatch} />,
    <YourAccommodation state={formStatePassport} dispatch={dispatch} />,
    <TheProperty state={formStatePassport} dispatch={dispatch} />,
    <Assets state={formStatePassport} dispatch={dispatch} />,
    <YourSecondHouse state={formStatePassport} dispatch={dispatch} />,
    <YourThirdHouse state={formStatePassport} dispatch={dispatch} />,
    <FinancialAssets state={formStatePassport} dispatch={dispatch} />,
    <OtherPartyAssets state={formStatePassport} dispatch={dispatch} />,
    <YourPreviousRelationshipDetails
      state={formStatePassport}
      dispatch={dispatch}
    />,
    <ClientDeclaration state={formStatePassport} dispatch={dispatch} />,
  ]);

  return (
    <>
      {isSubmitted === true ? (
        <Submitted redirect={redirect} />
      ) : (
        <FormComponent
          logo={logo}
          currentStepIndex={currentStepIndex}
          formStatePassport={formStatePassport}
          isFirstStep={isFirstStep}
          isLastStep={isLastStep}
          step={step}
          steps={steps}
          next={next}
          goTo={goTo}
          back={back}
          setIsSubmitted={setIsSubmitted}
          setRedirect={setRedirect}
        />
      )}
    </>
  );
};

export default Form;
